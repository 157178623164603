
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import {
  validateIsEmail,
  validateNotEmpty,
  validateParticipantMinAge,
  validateParticipantMaxAge,
} from "@/shared/helpers/validationHelpers";
import { InvitePerson } from "@/shared/interfaces/InvitePerson.interface";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { MemberOrganization } from "./ParticipantsTable.vue";
import { InvitePersonStatus } from "@/shared/enums/InvitePersonStatus.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";

export default defineComponent({
  name: "CourseAddEditPersonInviteModal",
  components: { BaseModalForm },
  emits: ["close", "newPerson"],
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    selectedItem: {
      type: Object as PropType<InvitePerson>,
      required: true,
    },
    memberOrganizations: {
      type: Array as PropType<MemberOrganization[]>,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const personModel = ref<InvitePerson>(getPersonInitialData(store.state.hoc.usePreFilledValues));
    const currentYear = new Date().getFullYear();
    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        return;
      }
      if (props.modalType === ModalType.Add) {
        emit("newPerson", personModel.value);
        emit("close");
      }
      if (props.modalType === ModalType.Edit) {
        const { firstName, lastName, email, mobile, birthYear } = personModel.value;

        personModel.value.status = InvitePersonStatus.Success;

        if (birthYear) {
          if (+birthYear > +currentYear - 10) {
            openNotification(store, NotificationItemType.Warning, "En eller flere deltaker er under 10 år");
            personModel.value.status = InvitePersonStatus.Warning;
          }
        }

        if (!firstName || !lastName || !email || !mobile) {
          personModel.value.status = InvitePersonStatus.Failed;
        }
        emit("close");
      }
    };

    onMounted(() => {
      if (props.modalType === ModalType.Edit) {
        personModel.value = props.selectedItem;
      }
    });

    return {
      personModel,
      validateNotEmpty,
      validateIsEmail,
      handleSubmit,
      validateParticipantMinAge,
      validateParticipantMaxAge,
    };
  },
});

const getPersonInitialData = (usePreFilledValues: boolean): InvitePerson => {
  if (usePreFilledValues) {
    return {
      firstName: "Greger",
      lastName: "Hemb",
      email: "jan.g@h.g",
      mobile: "45678954",
      createRestrictedUser: false,
      sendEmail: true,
      sendSms: true,
      uuid: generateUuid(),
    };
  }
  return {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    createRestrictedUser: false,
    sendEmail: true,
    sendSms: true,
    uuid: generateUuid(),
  };
};
