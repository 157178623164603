var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.course ? _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseInvites
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }], null, false, 942037181)
  }, [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_c('CourseAddEditPersonInviteModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "selectedItem": _vm.modalData.existingItem,
      "memberOrganizations": _vm.orderedMemberOrganizations
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "newPerson": _vm.addPerson
    }
  })], 1) : _vm._e(), _c('BaseSheet', {
    staticClass: "pa-4",
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Velg CSV-fil (vil erstatte innhold i tabell)",
      "usePrimaryColor": false
    }
  }), _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "md": "12"
    }
  }, [_c('v-file-input', {
    attrs: {
      "chips": "",
      "label": "CSV-fil for med personer",
      "accept": "text/csv,.csv"
    },
    on: {
      "change": _vm.onFileChange
    },
    model: {
      value: _vm.selectedFile,
      callback: function callback($$v) {
        _vm.selectedFile = $$v;
      },
      expression: "selectedFile"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "md": "12"
    }
  }, [_c('v-alert', {
    staticClass: "my-0",
    attrs: {
      "outlined": "",
      "dense": "",
      "type": "info",
      "color": "primary"
    }
  }, [_vm._v(" Når du velger å legge inn deltakere på kurs via denne funksjonen forutsetter det at samtykke for oppbevaring av persondata er innhentet fra andre parter, iht kravene i GDPR ")])], 1), _c('v-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "href": "/files/examplefile-invites.csv",
      "download": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s("mdi-file-download") + " ")]), _vm._v(" Last ned eksempelfil ")], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('BaseTableSimple', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.peopleToInvite,
      "items-per-page": -1,
      "scrollable": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center align-center"
        }, [_vm._v("Personer som skal inviteres")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "primary",
            "outlined": ""
          },
          on: {
            "click": _vm.openAddNewPerson
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Legg til person ")], 1)];
      },
      proxy: true
    }, {
      key: "header.status",
      fn: function fn(_ref) {
        var header = _ref.header;
        return [_c('span', {
          staticClass: "header-checkbox-text"
        }, [_vm._v(" " + _vm._s(header.text) + " ")]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "color": "info"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mx-1",
                attrs: {
                  "color": "primary"
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" En linje vil få status \"feilet\" om den inneholder feilaktig informasjon. Dette kan du fikse ved å redigere deltakeren")])])];
      }
    }, {
      key: "header.createRestrictedUser",
      fn: function fn(_ref3) {
        var header = _ref3.header;
        return [_c('v-checkbox', {
          on: {
            "change": function change($event) {
              return _vm.onChangeSelectAllInvitationType(_vm.CourseInvitationType.CreateRestrictedUser, _vm.selectAllCreateRestrictedUser);
            }
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('span', {
                staticClass: "header-checkbox-text"
              }, [_vm._v(" " + _vm._s(header.text) + " ")]), _c('v-tooltip', {
                attrs: {
                  "top": "",
                  "color": "info"
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref4) {
                    var on = _ref4.on,
                        attrs = _ref4.attrs;
                    return [_c('v-icon', _vm._g(_vm._b({
                      staticClass: "mx-1",
                      attrs: {
                        "color": "primary"
                      }
                    }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
                  }
                }], null, true)
              }, [_c('span', [_vm._v(" En begrenset bruker vil ikke kunne motta epost og meldinger på min side ")])])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectAllCreateRestrictedUser,
            callback: function callback($$v) {
              _vm.selectAllCreateRestrictedUser = $$v;
            },
            expression: "selectAllCreateRestrictedUser"
          }
        })];
      }
    }, {
      key: "item.firstName",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "item.lastName",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "item.email",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "item.mobile",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "item.birthYear",
      fn: function fn(_ref9) {
        var value = _ref9.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "item.postAddress",
      fn: function fn(_ref10) {
        var value = _ref10.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "item.postZip",
      fn: function fn(_ref11) {
        var value = _ref11.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "item.sex",
      fn: function fn(_ref12) {
        var value = _ref12.value;
        return [_vm._v(" " + _vm._s(value ? value === "M" ? "Mann" : value === "K" ? "Kvinne" : "-" : "-") + " ")];
      }
    }, {
      key: "item.postCity",
      fn: function fn(_ref13) {
        var value = _ref13.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "header.sendEmail",
      fn: function fn(_ref14) {
        var header = _ref14.header;
        return [_c('v-checkbox', {
          on: {
            "change": function change($event) {
              return _vm.onChangeSelectAllInvitationType(_vm.CourseInvitationType.SendEmail, _vm.selectAllSendEmail);
            }
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('span', {
                staticClass: "header-checkbox-text"
              }, [_vm._v(" " + _vm._s(header.text) + " ")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectAllSendEmail,
            callback: function callback($$v) {
              _vm.selectAllSendEmail = $$v;
            },
            expression: "selectAllSendEmail"
          }
        })];
      }
    }, {
      key: "header.sendSms",
      fn: function fn(_ref15) {
        var header = _ref15.header;
        return [_c('v-checkbox', {
          on: {
            "change": function change($event) {
              return _vm.onChangeSelectAllInvitationType(_vm.CourseInvitationType.SendSms, _vm.selectAllSendSms);
            }
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('span', {
                staticClass: "header-checkbox-text"
              }, [_vm._v(" " + _vm._s(header.text) + " ")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectAllSendSms,
            callback: function callback($$v) {
              _vm.selectAllSendSms = $$v;
            },
            expression: "selectAllSendSms"
          }
        })];
      }
    }, {
      key: "item.createRestrictedUser",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('v-checkbox', {
          on: {
            "change": function change($event) {
              return _vm.onChangeSelectInvitationType(_vm.CourseInvitationType.CreateRestrictedUser, item);
            }
          },
          model: {
            value: item.createRestrictedUser,
            callback: function callback($$v) {
              _vm.$set(item, "createRestrictedUser", $$v);
            },
            expression: "item.createRestrictedUser"
          }
        })];
      }
    }, {
      key: "item.sendEmail",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('v-checkbox', {
          on: {
            "change": function change($event) {
              return _vm.onChangeSelectInvitationType(_vm.CourseInvitationType.SendEmail, item);
            }
          },
          model: {
            value: item.sendEmail,
            callback: function callback($$v) {
              _vm.$set(item, "sendEmail", $$v);
            },
            expression: "item.sendEmail"
          }
        })];
      }
    }, {
      key: "item.memberOrganizationId",
      fn: function fn(_ref18) {
        var value = _ref18.value;
        return [_vm._v(" " + _vm._s(_vm.getMemberOrganizationNameById(value)) + " ")];
      }
    }, {
      key: "item.sendSms",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('v-checkbox', {
          on: {
            "change": function change($event) {
              return _vm.onChangeSelectInvitationType(_vm.CourseInvitationType.SendSms, item);
            }
          },
          model: {
            value: item.sendSms,
            callback: function callback($$v) {
              _vm.$set(item, "sendSms", $$v);
            },
            expression: "item.sendSms"
          }
        })];
      }
    }, {
      key: "actions",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref21) {
              var on = _ref21.on,
                  attrs = _ref21.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.editPerson(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger person")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref22) {
              var on = _ref22.on,
                  attrs = _ref22.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.deleteInvite(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-delete ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett person")])])];
      }
    }, {
      key: "after-table",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "mx-3",
          attrs: {
            "align": "right"
          }
        }, [_vm.peopleToInvite.length > 0 ? _c('BaseConfirmDialog', {
          attrs: {
            "actionButtonText": 'Slett alle'
          },
          on: {
            "confirm": _vm.clearPeopleList
          }
        }, [_vm._v(" Vil du slette alle i listen? ")]) : _vm._e(), _vm.peopleToInvite.length > 0 ? _c('v-btn', {
          staticClass: "ml-3",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.sendInvites
          }
        }, [_vm._v(" Send invitasjoner ")]) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }], null, true)
  })], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "290"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        _vm.warningDialog = false;
      }
    },
    model: {
      value: _vm.warningDialog,
      callback: function callback($$v) {
        _vm.warningDialog = $$v;
      },
      expression: "warningDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v("Er du sikker?")]), _c('v-card-text', [_vm._v("Det er en eller flere rader med status \"Advarsel\", ønsker du fortsatt å sende invitasjon?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": ""
    },
    on: {
      "click": _vm.rerunSendInvites
    }
  }, [_vm._v(" Bekreft ")]), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.warningDialog = false;
      }
    }
  }, [_vm._v(" Avbryt ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }