var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Fornavn *",
      "rules": _vm.validateNotEmpty,
      "required": "",
      "outlined": ""
    },
    model: {
      value: _vm.personModel.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "firstName", $$v);
      },
      expression: "personModel.firstName"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Etternavn *",
      "rules": _vm.validateNotEmpty,
      "required": "",
      "outlined": ""
    },
    model: {
      value: _vm.personModel.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "lastName", $$v);
      },
      expression: "personModel.lastName"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "E-post * ",
      "rules": _vm.validateIsEmail,
      "required": "",
      "outlined": ""
    },
    model: {
      value: _vm.personModel.email,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "email", $$v);
      },
      expression: "personModel.email"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-phone-input', {
    attrs: {
      "rules": _vm.validateNotEmpty,
      "label": "Mobilnummer *",
      "country-icon-mode": "svg",
      "outlined": ""
    },
    model: {
      value: _vm.personModel.mobile,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "mobile", $$v);
      },
      expression: "personModel.mobile"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "pb-5",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('v-divider', [_vm._v(" inset ")])], 1)], 1), _c('v-row', [_c('h2', {
    staticClass: "non-required--text pb-3"
  }, [_vm._v("Valgfrie felter:")])]), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Gatenavn",
      "required": ""
    },
    model: {
      value: _vm.personModel.postAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "postAddress", $$v);
      },
      expression: "personModel.postAddress"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "3",
      "md": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Postnr",
      "required": ""
    },
    model: {
      value: _vm.personModel.postZip,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "postZip", $$v);
      },
      expression: "personModel.postZip"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "9",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Poststed",
      "required": ""
    },
    model: {
      value: _vm.personModel.postCity,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "postCity", $$v);
      },
      expression: "personModel.postCity"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "label": "Kjønn",
      "items": [{
        label: 'Mann',
        value: 'M'
      }, {
        label: 'Kvinne',
        value: 'K'
      }],
      "rules": [_vm.validateNotEmpty],
      "item-text": "label",
      "item-value": "value"
    },
    model: {
      value: _vm.personModel.sex,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "sex", $$v);
      },
      expression: "personModel.sex"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Fødselsår",
      "required": "",
      "rules": [].concat(_vm.validateParticipantMaxAge, _vm.validateParticipantMinAge)
    },
    model: {
      value: _vm.personModel.birthYear,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "birthYear", $$v);
      },
      expression: "personModel.birthYear"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "label": "Medlemsorganisasjon",
      "items": _vm.memberOrganizations,
      "item-text": "name",
      "item-value": "id"
    },
    model: {
      value: _vm.personModel.memberOrganizationId,
      callback: function callback($$v) {
        _vm.$set(_vm.personModel, "memberOrganizationId", $$v);
      },
      expression: "personModel.memberOrganizationId"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }