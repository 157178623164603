
import { api } from "@/api/api";
import { ApiCreateCourseInvitationDto } from "@/api/generated/Api";
import CourseAddEditPersonInviteModal from "@/components/course/details/CourseAddEditPersonInviteModal.vue";
import { MemberOrganization } from "@/components/course/details/ParticipantsTable.vue";
import BaseConfirmDialog from "@/components/shared/BaseConfirmDialog.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import BaseTableSimple from "@/components/shared/table/BaseTableSimple.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseInvitationType } from "@/shared/enums/CourseInvitationType.enum";
import { InvitePersonStatus } from "@/shared/enums/InvitePersonStatus.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { getOrderedMemberOrganizations } from "@/shared/helpers/getters";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { InvitePerson } from "@/shared/interfaces/InvitePerson.interface";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "CourseInvitesNewPage",
  components: {
    BaseTableSimple,
    CourseAddEditPersonInviteModal,
    BaseModal,
    BaseSheet,
    BaseSheetHeader,
    BaseConfirmDialog,
    BaseLayout,
  },

  setup() {
    const store = useStore<StoreState>();
    const selectedFile = ref<File>();
    const peopleToInvite = ref<InvitePerson[]>([]);
    const modalData = ref(getInitialModalData());
    const route = useRoute();
    const router = useRouter();
    const selectAllCreateRestrictedUser = ref(false);
    const selectAllSendEmail = ref(false);
    const selectAllSendSms = ref(false);
    const bypassWarning = ref(false);
    const warningDialog = ref(false);
    const orderedMemberOrganizations = ref<MemberOrganization[]>([]);

    watch(peopleToInvite, () => updateHeaderCheckboxes());

    onMounted(() => {
      updateHeaderCheckboxes();
      setOrderedMemberOrganizations();
    });

    const rerunSendInvites = () => {
      bypassWarning.value = true;
      warningDialog.value = false;
      sendInvites();
    };

    const updateHeaderCheckboxes = () => {
      const filterInvites = (field: keyof typeof CourseInvitationType) =>
        !!peopleToInvite.value?.length &&
        !peopleToInvite.value?.filter((person) => !person[CourseInvitationType[field]])?.length;

      selectAllCreateRestrictedUser.value = filterInvites("CreateRestrictedUser");
      selectAllSendEmail.value = filterInvites("SendEmail");
      selectAllSendSms.value = filterInvites("SendSms");
    };

    const setOrderedMemberOrganizations = async () => {
      orderedMemberOrganizations.value = (await getOrderedMemberOrganizations()) || [];
    };

    const getMemberOrganizationNameById = (id?: number) => {
      if (id === undefined || id === null) {
        return "Ingen medlemsorganisasjon";
      }
      return orderedMemberOrganizations.value?.find((office) => office.id === id)?.name || "Ukjent navn";
    };

    const getMemberOrganizationIdByName = (name?: string) => {
      const trimmedName = name?.toLowerCase();

      if (trimmedName === "") {
        return null;
      }

      return orderedMemberOrganizations.value?.find((office) => office.name?.toLowerCase() === trimmedName)?.id || 0;
    };

    const onFileChange = () => {
      if (!selectedFile.value) {
        return;
      }
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          if (typeof reader.result === "string") {
            peopleToInvite.value = getUsersFromFile(reader.result.replace(/[\r]+/g, ""));
            updateHeaderCheckboxes();
          }
        },
        false
      );
      reader.readAsText(selectedFile.value);
    };

    const acceptableGenders = {
      male: ["mann", "m", "man"],
      woman: ["kvinne", "k", "kveg", "kvine"],
    };

    const getUsersFromFile = (fileContent: string): InvitePerson[] => {
      const lines = fileContent.split("\n");
      const [headerLine, ...dataLines] = lines;
      if (
        dataLines.length === 0 ||
        headerLine.trim() !==
          "fornavn;etternavn;epost;mobil;valgfrie felter ->;postaddresse;postnummer;poststed;kjønn;fødselsår;medlemsorganisasjon"
      ) {
        openNotification(store, NotificationItemType.Error, "Feil under parsing av CSV-fil");
        return [];
      }

      return dataLines
        .filter((x) => x.length)
        .map((line) => {
          const currentYear = new Date().getFullYear();

          const [
            firstName,
            lastName,
            email,
            mobile,
            ,
            postAddress,
            postZip,
            postCity,
            sex,
            birthYear,
            memberOrganizationName,
          ] = line.split(";");

          let _sex = sex;

          let status = InvitePersonStatus.Success;

          if (_sex) {
            const trimmedSex = sex.trim().toLowerCase();
            _sex = "";

            if (acceptableGenders.male.includes(trimmedSex) || acceptableGenders.woman.includes(trimmedSex)) {
              if (acceptableGenders.male.includes(trimmedSex)) {
                _sex = "M";
              }

              if (acceptableGenders.woman.includes(trimmedSex)) {
                _sex = "K";
              }
            }

            if (_sex === "") {
              status = InvitePersonStatus.Failed;
              openNotification(store, NotificationItemType.Warning, "Deltaker må være mann eller kvinne");
            }
          }

          if (birthYear) {
            if (+birthYear > +currentYear - 10) {
              status = InvitePersonStatus.Warning;
              openNotification(store, NotificationItemType.Warning, "En eller flere deltaker er under 10 år");
            }
          }

          if (mobile) {
            if (mobile.toString().charAt(0) !== "+") {
              status = InvitePersonStatus.Failed;
              openNotification(store, NotificationItemType.Warning, `Mobilnummer må ha "+" og landskode`);
            }
          }

          if (!firstName || !lastName || !email || !mobile) {
            status = InvitePersonStatus.Failed;
            openNotification(
              store,
              NotificationItemType.Warning,
              "En eller flere personer mangler navn, epost eller mobilnummer, status feilet"
            );
          }

          const memberOrganizationId = getMemberOrganizationIdByName(memberOrganizationName);

          if (memberOrganizationId === 0) {
            openNotification(store, NotificationItemType.Warning, "Medlemsorganisasjon finnes ikke");
          }

          return {
            firstName,
            lastName,
            email,
            mobile: mobile.trim(),
            createRestrictedUser: false,
            postAddress,
            postZip,
            postCity,
            sex: _sex,
            birthYear: +birthYear,
            sendEmail: true,
            sendSms: true,
            uuid: generateUuid(),
            status,
            memberOrganizationId,
          };
        });
    };

    const deleteInvite = (item: InvitePerson) => {
      peopleToInvite.value = peopleToInvite.value.filter((current) => current.uuid !== item.uuid);
      updateHeaderCheckboxes();
    };

    const editPerson = useOpenModal(ModalType.Edit, "person", modalData);

    const openAddNewPerson = useOpenModal(ModalType.Add, "person", modalData);

    const addPerson = (person: InvitePerson) => {
      peopleToInvite.value.push(person);
      updateHeaderCheckboxes();
    };

    const clearPeopleList = () => {
      peopleToInvite.value = [];
      selectedFile.value = undefined;
      updateHeaderCheckboxes();
    };

    const getInvitePersonByUuid = (uuid: string) => peopleToInvite.value.find((person) => person.uuid === uuid);

    const onChangeSelectInvitationType = (invitationType: CourseInvitationType, person: InvitePerson) => {
      const selectedPerson = getInvitePersonByUuid(person.uuid);
      if (!selectedPerson) {
        return;
      }
      if (invitationType === CourseInvitationType.CreateRestrictedUser && selectedPerson.createRestrictedUser) {
        selectedPerson.sendEmail = false;
        selectedPerson.sendSms = false;
      }
      if (invitationType === CourseInvitationType.SendEmail || invitationType === CourseInvitationType.SendSms) {
        selectedPerson.createRestrictedUser = false;
      }
      updateHeaderCheckboxes();
    };

    const onChangeSelectAllInvitationType = (invitationType: CourseInvitationType, isSelectedAll: boolean) => {
      if (invitationType === CourseInvitationType.CreateRestrictedUser) {
        peopleToInvite.value.map((person) => {
          person.createRestrictedUser = isSelectedAll;
          if (isSelectedAll) {
            person.sendEmail = false;
            person.sendSms = false;
          }
        });
        selectAllSendEmail.value = isSelectedAll ? false : selectAllSendEmail.value;
        selectAllSendSms.value = isSelectedAll ? false : selectAllSendSms.value;
      }
      if (invitationType === CourseInvitationType.SendEmail || invitationType === CourseInvitationType.SendSms) {
        peopleToInvite.value.map((person) => {
          if (invitationType === CourseInvitationType.SendEmail) {
            person.sendEmail = isSelectedAll;
          } else {
            person.sendSms = isSelectedAll;
          }
          if (isSelectedAll) {
            person.createRestrictedUser = false;
          }
        });
        selectAllCreateRestrictedUser.value = isSelectedAll ? false : selectAllCreateRestrictedUser.value;
      }
    };

    const sendInvites = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (peopleToInvite.value.some((x) => x.status === InvitePersonStatus.Failed)) {
          openNotification(
            store,
            NotificationItemType.Error,
            "En eller flere personer inneholder feilaktig informasjon"
          );
          return;
        }

        if (peopleToInvite.value.some((x) => x.status === InvitePersonStatus.Warning)) {
          if (!bypassWarning.value) {
            warningDialog.value = true;
            return;
          }
        }

        const mappedPeople: ApiCreateCourseInvitationDto[] = peopleToInvite.value.map(
          ({
            firstName,
            lastName,
            email,
            mobile,
            createRestrictedUser,
            sendEmail,
            sendSms,
            sex,
            postAddress,
            postCity,
            postZip,
            birthYear,
            memberOrganizationId,
          }) => ({
            firstName,
            lastName,
            emailAddress: email.trim(),
            phoneNumber: mobile,
            createRestrictedUser,
            sendEmail,
            sendSms,
            postZip,
            postCity,
            postAddress,
            sex,
            birthYear: birthYear || null,
            memberOrganizationId: memberOrganizationId || null,
          })
        );

        try {
          await api.course.createInvitationsAsync(+route.params.id, mappedPeople);
          openNotification(store, NotificationItemType.Success, "Invitasjonene er sendt til alle personer i listen");
          router.push({
            name: SingleCourseRouteNames.CourseInvites,
            params: {
              id: route.params.id,
            },
          });
        } catch (e: any) {
          const { errors } = e.response.data;
          errors?.forEach((error: any) => {
            openNotification(store, NotificationItemType.Error, `Valideringsfeil: ${error.message}`);
          });
          errors?.forEach((errorList: any) => {
            errorList.invitations?.forEach((error: any) => {
              openNotification(store, NotificationItemType.Error, `Valideringsfeil: ${error.value}`);
            });
          });
        }
      });
    };

    return {
      course: computed(() => store.state.courses.course),
      modalData,
      selectedFile,
      onFileChange,
      headers,
      peopleToInvite,
      deleteInvite,
      openAddNewPerson,
      addPerson,
      clearPeopleList,
      sendInvites,
      CourseInvitationType,
      onChangeSelectInvitationType,
      onChangeSelectAllInvitationType,
      selectAllCreateRestrictedUser,
      selectAllSendEmail,
      selectAllSendSms,
      navigateToCourseInvites: () => useNavigateBack(SingleCourseRouteNames.CourseDashboard),
      editPerson,
      orderedMemberOrganizations,
      getMemberOrganizationNameById,
      acceptableGenders,
      warningDialog,
      rerunSendInvites,
    };
  },
});

const headers = [
  {
    text: "Import",
    value: "status",
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
  {
    text: "Fornavn",
    value: "firstName",
  },
  {
    text: "Etternavn",
    value: "lastName",
  },
  {
    text: "E-post",
    value: "email",
  },
  {
    text: "Mob.nr",
    value: "mobile",
  },
  {
    text: "Adresse",
    value: "postAddress",
  },
  {
    text: "Postnr",
    value: "postZip",
  },
  {
    text: "Poststed",
    value: "postCity",
  },
  {
    text: "Kjønn",
    value: "sex",
  },
  {
    text: "Fødselsår",
    value: "birthYear",
  },

  {
    text: "Medlemsorganisasjon",
    value: "memberOrganizationId",
  },
  {
    text: "Begrenset Bruker",
    value: "createRestrictedUser",
    sortable: false,
  },
  {
    text: "Send E-post",
    value: "sendEmail",
    sortable: false,
  },
  {
    text: "Send SMS",
    value: "sendSms",
    sortable: false,
  },
];
